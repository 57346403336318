import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Alert, Button, FormControl, FormGroup, Modal } from 'react-bootstrap';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import ShowLineError from '../comuns/showLineError/show-line-error';
import './login.css';
import logoLogin from '../../imagens/logoLogin.PNG';
import logoGoogle from '../../imagens/google-play.jpg';
import logoApple from '../../imagens/apple-store.jpg';
import LoadingFenix from '../loading-fenix/loading-fenix';
import { connect } from 'react-redux';
import * as actions from '../../action/login/login-action';
import { recuperaSomenteNumerosdeUmaString } from '../util/numeros/numeroUtil';
import {
    LABEL_COMUNS,
    CAMPOS,
    ENTER,
    MASCARA_CAMPOS,
    TIPO_DE_ACESSO,
    MENSAGENS,
    USUARIO_ARMAZENADO,
} from '../util/contantes-comuns/constantes-comuns';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import { validarDadosFormLogin, validarMfaCode } from '../../servicos/login/login-service';
import { retornaObjetoTarget } from '../../servicos/base/service-base';
import {environment} from '../../configuracoes/environment';



class Login extends Component {
    state = {
        exibirModal: false,
        tipo_acesso: TIPO_DE_ACESSO.PESSOA_FISICA,
        lembrarMe: reactLocalStorage.get(CAMPOS.LEMBRAR_ME) || false,
        usuario: '',
        senha: '',
        validarUsuarioAdmin: true,
        redirecionar: false,
        codeMFA: '',
        messageMfaError: '',
        mfaCriadoComSucesso: false,
        erroValidarMfa: false,
        exibir: false
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.isExisteLembrarMe()) {
            this.setState({ lembrarMe: true, usuario: USUARIO_ARMAZENADO() });
        }
    }

    componentWillReceiveProps(nextProps) {
        const { loginEfetuadoComSucesso, loginNaoEfetuado } = nextProps.login;
        if (
            (loginEfetuadoComSucesso && loginEfetuadoComSucesso !== this.props.login.loginEfetuadoComSucesso) ||
            loginNaoEfetuado
        ) {
            this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_LOADING);
        }
    }

    isExisteLembrarMe() {
        return reactLocalStorage.get(CAMPOS.LEMBRAR_ME);
    }

    pessionarEnter = e => {
        if (e.key === ENTER) {
            // this.validarLoginRedux()
            this.efetuarLogin();
        }
    };

    pressEnter = e => {
        const { qrCodeChecked } = this.props.login;
        if (e.key === ENTER) {
            const isQrCodeChecked = !qrCodeChecked ? this.criarMFA() : this.validateToken();
            return isQrCodeChecked;
        }
    }

    mudarFoco = e => {
        if (e.key === ENTER) {
            document.getElementById(CAMPOS.PASSWORD).focus();
        }
    };

    setLoginCheckBox = checkBoxSelectedValue => {
        if (checkBoxSelectedValue) {
            reactLocalStorage.set(CAMPOS.LEMBRAR_ME, LABEL_COMUNS.SIM.toLowerCase());
            reactLocalStorage.set(CAMPOS.USUARIO, this.state.usuario);
        } else {
            reactLocalStorage.set(CAMPOS.LEMBRAR_ME, 'nao');
            reactLocalStorage.set(CAMPOS.USUARIO, '');
        }
        this.setState({ lembrarMe: checkBoxSelectedValue });
    };

    setPropsCampos = (e, nomeDoCampo) => {

        let valorDoCampo = e && e.target && e.target.value;
        if (nomeDoCampo === CAMPOS.LEMBRAR_ME) {
            valorDoCampo = e.target.checked;
            if (valorDoCampo) {
                reactLocalStorage.set(CAMPOS.LEMBRAR_ME, LABEL_COMUNS.SIM.toLowerCase());
                reactLocalStorage.set(CAMPOS.USUARIO, this.state.usuario);
            } else {
                reactLocalStorage.set(CAMPOS.LEMBRAR_ME, 'nao');
                reactLocalStorage.set(CAMPOS.USUARIO, '');
            }
        }

        if (nomeDoCampo === CAMPOS.USUARIO) {
            if (this.state.lembrarMe) {
                reactLocalStorage.set(CAMPOS.USUARIO, valorDoCampo);
            }
        }

        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    };

    render() {
        const { loginEfetuadoComSucesso } = this.props.login;
        const { urlQRCode } = this.props.login;
        const { mfaCriadoComSucesso } = this.props.login;

        if (loginEfetuadoComSucesso) {
            return <Redirect to="/" />;
        }

        return (
            <div>
                <header className="App-header fundo-azul-claro">
                    <img src={logoLogin} className="App-logo" alt="imagem login" />
                </header>
                <div className="fundo-azul-login fundo-azul-escuro" style={{ overflow: 'scroll' }}>
                    <div className="login">

                        <div className="row bg-warning border box-shadow  border-radius">
                            <h1 className="col-md-2" style={{ color: 'black' }}><span className="glyphicon glyphicon-exclamation-sign"></span></h1>
                            <h4 className="col-md-9" style={{ color: 'black', fontSize: 14 }} >
                                Duplo Fator de Autenticação BPP Web e APP BPP Digital. <br />A partir de março/2023 contamos com a autenticação de duplo fator para login no APP BPP Digital e no BPP Web <a href="https://www.web.bpp.com.br/">https://www.web.bpp.com.br/</a>.
                                <br />Para visualizar o passo a passo de acesso e ter mais informações, <a onClick={onButtonClick}>clique aqui</a>.
                            </h4>
                        </div>

                        <div className="login-box">{mfaCriadoComSucesso !== undefined && !mfaCriadoComSucesso ? this.montarTelaMfa() : this.montarTelaCredenciais()}</div>

                    </div>
                </div>
                <Modal show={this.state.exibirModal} onHide={() => this.fecharModalCadastreSe()}>
                    <Modal.Body>
                        <br />
                        <p className="modal-cadastre-se">{MENSAGENS.LOGIN.CADASTRO}</p>
                        <br />
                        <div className="row modal-cadastre-se">
                            <a href="https://goo.gl/jwJFEb">
                                <img src={logoGoogle} className="App-logo" alt="PlayStore" />
                            </a>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <a href="https://goo.gl/hJUrEJ">
                                <img src={logoApple} className="App-logo" alt="IOs" />
                            </a>
                        </div>
                        <br />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="modal-cadastre-se">
                            <Button bsStyle="default" onClick={() => this.fecharModalCadastreSe()}>
                                {LABEL_COMUNS.SAIR}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }

    montarTelaCredenciais() {
        return (
            <div className="login-body">
                <h3 className="texto-preto">BPP Web</h3>
                <p className="texto-preto">Insira seus dados para acessar</p>
                {this.exibirErro()}
                <form className="login-body">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 campo_cpf">
                        <div className="form-group">
                            <FormGroup
                                validationState={this.state.usuarioErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}
                            >
                                <p className="texto_login">{LABEL_COMUNS.CPF}</p>
                                <InputMask
                                    mask={MASCARA_CAMPOS.CPF}
                                    maskChar=""
                                    className="mensagens-erro form-control"
                                    id="username"
                                    name="username"
                                    placeholder={LABEL_COMUNS.DIGITE_CPF}
                                    value={this.state.usuario}
                                    onKeyPress={this.mudarFoco}
                                    onChange={e => this.setPropsCampos(e, CAMPOS.USUARIO)}
                                />
                                <ShowLineError error={this.state.usuarioErro} />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-lembrarme">
                        <input
                            type="checkbox"
                            className="ckb-lembra-me"
                            id="lembrarMe"
                            checked={this.state.lembrarMe}
                            onChange={e => this.setPropsCampos(e, CAMPOS.LEMBRAR_ME)}
                        // onClick={e => this.setLoginCheckBox(e.target.checked)}
                        />
                        <label htmlFor="lembraMe" id="label-lembrar-me">
                            {LABEL_COMUNS.LEMBRAR_ME}
                        </label>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 campo_senha">
                        <div className="form-group">
                            <FormGroup
                                validationState={this.state.senhaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}
                            >
                                <p className="texto_login">{LABEL_COMUNS.SENHA.toUpperCase()}</p>
                                <input
                                    type="password"
                                    className="mensagens-erro form-control"
                                    id="senha"
                                    name="senha"
                                    placeholder={LABEL_COMUNS.DIGITE_SENHA}
                                    required={true}
                                    /*   onKeyPress={this.pessionarEnter} /> */
                                    onKeyPress={this.pessionarEnter}
                                    onChange={e => this.setPropsCampos(e, CAMPOS.SENHA)}
                                />
                                <ShowLineError error={this.state.senhaErro} />
                            </FormGroup>
                            {/* <p className="texto-senha-dica">{LABEL_COMUNS.SENHA_CRIADA_NO_APP}</p> */}
                            <a href={environment.urlForgotPwd}>Esqueci minha senha</a>
                        </div>
                    </div>
                    <div className="form-group">{this.exibirCarregando()}</div>
                </form>
            </div>
        );
    }

    montarTelaMfa() {
        const { qrCodeChecked } = this.props.login;
        const { erroValidarMfa } = this.props.login;
        return (
            <div className="mfa-body">
                <h3 className="texto-preto" style={{ fontSize: '20px' }}>Autenticação de dois fatores</h3>
                <p className="texto-preto">Mais segurança com a verificação de duas-etapas para a sua conta</p>
                <form className="mfa-body">
                    <p className="texto-preto" style={{ fontSize: '16px', textAlign: 'justify' }}>
                        - Abra o aplicativo Google Authenticator em seu dispositivo (Android/iOs).
                    </p>
                    {!qrCodeChecked && this.mostrarQrCode()}
                    <div className="form-group input-form">
                        <p className="texto-preto" style={{ fontSize: '16px', textAlign: 'justify' }}>
                            - Forneça o código de autenticação:
                        </p>
                        <input
                            type="number"
                            onInput={(e) => e.target.value = e.target.value.slice(0, 6)}
                            maxLength={6}
                            onKeyPress={this.pressEnter}
                            className="form-control"
                            onChange={e => this.setPropsCampos(e, CAMPOS.CODE_MFA)}
                            id="token"
                            name="token"
                        />
                        <ShowLineError error={erroValidarMfa ? '' : this.state.messageMfaError} />
                        <input
                            style={{ display: "none" }}
                        />
                        {erroValidarMfa ? <ShowLineError error="Token inválido. Digite novamente" /> : null}
                    </div>

                    <button type="button" className="btn btn-success btn-block" onClick={!qrCodeChecked ? this.criarMFA : this.validateToken}>
                        VALIDAR
                    </button>
                </form>
                <div className="form-group">{this.exibirLoading()}</div>
            </div>
        );
    }

    mostrarQrCode() {
        const svgStr = this.props.login.urlQRCode;
        const svg = new Blob([svgStr], { type: 'image/svg+xml' });
        const url = URL.createObjectURL(svg);
        return (
            <div>
                <p className="texto-preto" style={{ fontSize: '16px', textAlign: 'justify' }}>
                    - Digitalize o QR Code abaixo:
                </p>
                <img src={url} alt="QR Code" style={{ width: '12em' }} />
            </div>
        );
    }

    efetuarLogin = e => {
        const erros = validarDadosFormLogin(this.state);
        if (!Object.getOwnPropertyNames(erros).length) {
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
            this.props.postMfa(this.state);
        } else {
            this.setState(erros);
        }
    };

    criarMFA = e => {
        const erros = validarMfaCode(this.state);
        if (!Object.getOwnPropertyNames(erros).length) {
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
            this.props.createMFA(this.props.login, this.state.codeMFA);
        } else {
            this.setState(erros);
        }
    };

    validateToken = e => {

        if (!this.state.codeMFA) {
            return this.setState({ messageMfaError: 'Preencha o campo de código para autenticação.' })
        }

        this.setState({ messageMfaError: '' })
        this.setState({ exibir: true });
        this.props.login.erroValidarMfa = false;
        const erros = validarMfaCode(this.state);
        if (!Object.getOwnPropertyNames(erros).length) {
            this.exibirLoading();
            this.props.validateMFA(this.props.login, this.state.codeMFA);
        } else {
            this.setState({ messageMfaError: '' })
            this.setState(erros);
            this.setState({ exibir: false });
        }
    };


    fecharModalCadastreSe() {
        this.setState({ exibirModal: false });
    }

    exibirErro() {
        if (this.props.login.loginNaoEfetuado) {
            return (
                <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 ususenha_invalidos ">
                            <span>{this.props.login.loginNaoEfetuado}</span>
                        </div>
                    </div>
                </Alert>
            );
        }
    }

    ocultarErro() {
        if (this.props.dadosLogin.erro) {
            this.props
                .dispatch
                // loginAction.setOcultarErro()
                ();
        }
    }

    exibirLoading() {
        const { erroValidarMfa } = this.props.login;
        if (this.state.exibir && !erroValidarMfa) {
            return (
                <div>
                    <LoadingFenix />
                </div>
            );
        }
    }

    exibirCarregando() {
        const { mfaCriadoComSucesso } = this.props.login;
        if (this.state.exibirLoading && !mfaCriadoComSucesso) {
            return (
                <div className="alinhamento-centro espacamento_quadrado">
                    <LoadingFenix />
                </div>
            );
        } else {
            return (
                <div>
                    <button type="button" className="btn btn-success btn-block" onClick={this.efetuarLogin}>
                        {LABEL_COMUNS.ACESSAR.toUpperCase()}
                    </button>
                </div>
            );
        }
    }

    validarLoginRedux() {
        this.ocultarErro();
        //loginAction.setAtivarDesativarProcurandoAction(true);
        reactLocalStorage.set(CAMPOS.ESTA_AUTENTICADO, 'NAO');
        reactLocalStorage.set(CAMPOS.TOKEN, '');
        reactLocalStorage.set('tipoAcesso', TIPO_DE_ACESSO.PESSOA_FISICA);
        reactLocalStorage.set(CAMPOS.PESSOA_ID, '');
        reactLocalStorage.set(CAMPOS.STATUS_USUARIO, '');
        reactLocalStorage.set(CAMPOS.NOME_USUARIO, '');
        if (!this.isExisteLembrarMe()) {
            reactLocalStorage.set('usuario', '');
        }
        reactLocalStorage.set('email', '');
        reactLocalStorage.set('usuarioAdmin', 'NAO');
        //reactLocalStorage.set('contaSelecionada', []);
        reactLocalStorage.set('cartaoSelecionado', []);
        reactLocalStorage.set('tamanhoPaginaListaContas', 3);
        reactLocalStorage.set('tamanhoPaginaListaCartoes', 3);
        reactLocalStorage.set('tamanhoPaginaConsultaCargaLote', 10);
        reactLocalStorage.set('tamanhoPaginaConsultaImagensPersonalizadas', 3);
        reactLocalStorage.set('anexos', []); //reactLocalStorage.setObject('anexos', undefined);
        let usuario = '';
        if (!this.isExisteLembrarMe()) {
            usuario = document.getElementById('username').value;
            usuario = recuperaSomenteNumerosdeUmaString(usuario);
        } else {
            usuario = recuperaSomenteNumerosdeUmaString(this.state.usuario);
        }
        let senha = document.getElementById('password').value;
        reactLocalStorage.set('usuario', usuario);
        this.props.dadosLogin.lembrarMe = usuario;
        //this.props.dispatch(loginAction.postToken(senha));
    }
    buscarDadosPessoa() {
        //this.props.dispatch(loginAction.carregarDadosPessoa());
    }
    validarUsuarioAdmin() {
        // this.props.dispatch(loginAction.validarUsuarioAdmin());
    }

    buscaEmpresasOutorgadas(pessoaId) {
        // this.props.dispatch(loginAction.carregarEmpresasOutorgadas(pessoaId));
    }
}

function redirecionar() {
    // window.location.href = '/'
}

const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('ManualDuploFatorBPPDigitalv2.0.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'ManualDuploFatorBPPDigitalv2.0.pdf';
            alink.click();
        });
    });
};

const mapStateToProps = state => ({ login: state.LoginReducer });

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Login);
