export const environment = {
    urlAPI: 'https://w2wqqf2jac.execute-api.us-east-1.amazonaws.com/v1',
    urlAPI_MFA: 'https://nc8zhs7yqf.execute-api.us-east-1.amazonaws.com/v1',
    urlAPI_v3: 'https://l9axct2m43.execute-api.us-east-1.amazonaws.com/v2',
    urlCorePlataform: 'https://core.bpp.com.br/gatewayapi',
    urlCoreWebShim: 'https://core.bpp.com.br/bppwebshim',
    urlAPIPass: 'https://d2607ctnh0.execute-api.us-east-1.amazonaws.com/Stage/api/bpp-digital/auth/fenix-changepass',
    urlAPIFenix: 'https://apifenix.bpp.com.br/',
    urlForgotPwd: 'https://www.altera-senha.bpp.com.br/fenix/',
    tokenId: '79ebc889-84a5-450d-b5c8-3844530cb45c',
    tokenChaveSecreta: '552f2ae3-1baa-4844-b8fd-a31b2c3da1b3',
    tokenId_core_plataform: 'f6e83b53-bd0b-4968-b256-926087a2b601',
    tokenChaveSecreta_core_plataform:
        'b5543e2f-8679-4404-845c-0de1b3736ec3',
    arquivosBancariosBucket: 'fxprod-fmp-arquivos',
    arquivosPedidos: 'imagens-personalizadas',
    documentosBucket: 'fng-contratos',
    devComprovantes: 'comprovantes-prestacao',
    bucket_avatar_conta: 'bpp-digital-avatar-prod',
    bucket_lote_arquivo: 'prod-arquivos-lote',
    modeloArquivoCargaCartao:
        'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-CARGA-LOTE-CARTAO.csv',
    modeloArquivoCargaConta:
        'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-CARGA-LOTE-CONTA.csv',
    modeloArquivoBppPagCargaCartao:
        'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-PAG-CARGA-CARTAO.csv',
    modeloArquivoBppPagTed:
        'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-PAG-TED.csv',
    modeloArquivoBppPagTransferenciaContas:
        'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-PAG-TRANSFERENCIA.csv',
    produtoPublicado: {
        contaMovimentoPf: '45b489c4-5c94-40f7-8bdc-65328ba1e5ee',
        contaCartaoPf: '',
        contaMovimebtoPj: '45b489c4-5c94-40f7-8bdc-65328ba1e5ee',
        contaCartaoPj: '',
        contaDepartamento: 'g09533ef-c311-4dc1-9419-1653170498b7',
        pedidoCartaoPessoaFisica: '4c9c4b80-0247-47b1-85b5-f0c1c4789511',
    },
    produto: '00571886-ac5f-11e8-98d0-529269fb1459',
    versao: '2.4.0',
    produtoPublicadoIdCriacaoContaPessoaFisica:
        '45b489c4-5c94-40f7-8bdc-65328ba1e5ee',
    produtoPublicadoIdComboAnexo: [
        {
            produtoPublicadoId: '5ea831d8-a109-4b2d-af5f-6da03d2e4c4a',
            felId: '89',
        },
        {
            produtoPublicadoId: '5507f204-43c4-454a-b094-51c8d0d7d63d',
            felId: '3817',
        },
        {
            produtoPublicadoId: 'be8b79bf-cc9e-4b94-839a-ebfe13cd22ec',
            felId: '3818',
        },
        {
            produtoPublicadoId: '4982c42f-09ed-498f-a22b-8339d814b8b4',
            felId: '3819',
        },
        {
            produtoPublicadoId: 'de9d29b3-8a13-4d54-8edf-fc043b22e622',
            felId: '3812',
        },
        {
            produtoPublicadoId: '4982c42f-09ed-498f-a22b-8339d814b8b4',
            felId: '4126',
        },
    ],
    produtoIdAnexo: [
        { produtoId: 'a6f50533-03c8-493c-9190-be93662fc913' },
        { produtoId: 'c0b06942-b6f6-11e8-96f8-529269fb1459' },
        { produtoId: '67ac41db-fa9b-41aa-93bd-7dee73065d5f' },
        { produtoId: '1417d97c-0e50-4583-bf9e-f6ed760a14db' },
        { produtoId: 'a8ac430f-647b-4868-8432-12111d199fd4' },
        { produtoId: 'fc1167bc-59b3-4918-8509-4d5f69ae9627' },
        { produtoId: 'b819503c-a3ac-493a-b623-c0ee62ab7e35' },
        { produtoId: '372d4a43-9103-475f-b7aa-08bd6751f5ab' },
    ],
    produtoIdAnexoBppPag: [
        { produtoId: 'cd4afba6-d07b-11e8-a8d5-f2801f1b9fd1' },
        { produtoId: '49e2bd36-e15f-11e8-9f32-f2801f1b9fd1' },
    ],
    produtoPublicadoPagInativo: '040fd4c2-d07c-11e8-a8d5-f2801f1b9fd1',
    produtoPublicadoCorpInativo: '4982c42f-09ed-498f-a22b-8339d814b8b4',
    produtoPublicadoCorpDebitoInativo:
        '5507f204-43c4-454a-b094-51c8d0d7d63d',
    produtoPublicadoCorpCreditoSemSaqueInativo:
        'be8b79bf-cc9e-4b94-839a-ebfe13cd22ec',
    produtoPublicadoCorpDebitoSemSaqueInativo:
        'de9d29b3-8a13-4d54-8edf-fc043b22e622',
    produtosIdsBppCorp: () =>
        environment.produtoIdAnexo
            .map(({ produtoId }) => produtoId)
            .join(','),
    produtosIdsBppPag: () =>
        environment.produtoIdAnexoBppPag
            .map(({ produtoId }) => produtoId)
            .join(','),
};
